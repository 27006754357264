import { POST_ENTER_CALL, SET_PARTICIPANTS } from "../actions/call";

const INITIAL_STATE = {
  call: {
    data: {},
    loading: false,
    error: false,
  },
  participants: {
    data: [],
  },
};

const call = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${POST_ENTER_CALL}_REJECTED`:
      return {
        ...state,
        call: {
          ...state.call,
          loading: false,
          error: true,
        },
      };

    case `${POST_ENTER_CALL}_PENDING`:
      return {
        ...state,
        call: {
          ...state.call,
          loading: true,
          error: false,
        },
      };

    case `${POST_ENTER_CALL}_FULFILLED`:
      return {
        ...state,
        call: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };

    case SET_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};

export default call;
