import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Error from "../components/commons/errors/Error";
import NotFound from "../components/commons/errors/NotFound";
import axios from "axios";
import config from "../config/config";
import RoomContainer from "../components/room/RoomContainer";
import Home from "../components/home/Home.js";

const Routing = () => {
  axios.defaults.baseURL = config.baseURL;
  axios.defaults.headers.common["X-Api-Key"] = config.apiKey;

  return (
    <Router>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<RoomContainer />} path="/room" />
        <Route element={<Error />} path="/error" />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
