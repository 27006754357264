import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "fit-content",
    alignSelf: "center",
    marginBottom: 30,
    marginTop: 30,
    boxShadow: "3px 5px 10px 0px grey",
    fontSize: 14,
    fontWeight: 500,
    border: `solid 1px ${theme.palette.primary.homeGradient1}`,
    [theme.breakpoints.down(801)]: {
      fontSize: 10,
      marginBottom: 35,
      marginTop: 10,
    },
  },
}));

const ErrorButton = ({ text, icon, action }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      type="sumbit"
      startIcon={icon}
      onClick={action}>
      {text}
    </Button>
  );
};

export default ErrorButton;
